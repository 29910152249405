//import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "../commonDataAccess";
import { IFilter } from "src/@redux/listRedux";
class peopleDataAccess {
  /**
   * Checks if the current token is valid
   * @returns the response sent by api
   */
  GetList = (params: {
    userType;
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      userType: params.userType,
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData("user/getall", parameters);
  };
  //   alterOrganization = (tenantId) =>  {
  //     return commonDataAccess.postData("Account/altertenant", null, {
  //       tenantId,
  //     })
  //   };
  getUserShifts = () => {
    return commonDataAccess.getData("user/get/usershifts");
  };

  getPeopleInfo = async (id: number) => {
    return await commonDataAccess.getData("user/get/userinfo", { id: id });
  };
  getUserTypeById = async (id: number) => {
    return await commonDataAccess.getData("user/get/userTypeById", { id: id });
  };
  getPeopleEmailList = () => {
    return commonDataAccess.getData("user/get/users-with-email");
  };
  getBDMByRefererId = (id: any) => {
    return commonDataAccess.getData("user/get/bdmByRefererId", { id: id });
  };
  getBDMAndCommissionRateByReferrerId = (id: any) => {
    return commonDataAccess.getData("user/get/bdmAndCommissionRateByReferrerId", { id: id });
  };
  getPeopleList = () => {
    return commonDataAccess.getData("user/get/users");
  };
  savePeopleInfo = (model: any) => {
    return commonDataAccess.postData("user/save/userinfo", model);
  };
  getContactDetails = (id: number) => {
    return commonDataAccess.getData("user/get/contactDetails", { id: id });
  };
  saveContactDetails = (model: any) => {
    return commonDataAccess.postData("user/save/contactDetails", model);
  };
  getPeoplesDropdown = () => {
    return commonDataAccess.getData("user/get/userlist-dropdown");
  };
  getActiveUsers = () => {
    return commonDataAccess.getData("user/get/activeusers");
  };

  getPasswordInfo = (userId) => {
    return commonDataAccess.getData("user/get/userpasswordinfo", {
      id: userId,
    });
  };
  resetPassword = (model: any) => {
    console.log(model);
    return commonDataAccess.postData("user/resetpassword", model);
  };

  getUsersWithDepartment = () => {
    return commonDataAccess.getData("user/get/userWithDept");
  };
  delete = (ids) => {
    return commonDataAccess.deleteData("user/delete", { ids: ids });
  };
  getUserDropdownByDepartment = (departmentId: any) => {
    return commonDataAccess.getData(`user/get/usernamevalue/${departmentId}`);
  };
  getUserListDropdownByDepartmentId = (departmentId: any) => {
    return commonDataAccess.getData(
      `user/get/user-list/bydepartment/${departmentId}`
    );
  };
  getUsersForDropdownByType = (userType: string) => {
    return commonDataAccess.getData(
      `user/get/user-list/byuserType/${userType}`
    );
  };
  getCurrentUserEmail = () => {
    return commonDataAccess.getData("user/get/currentUserEmail");
  };

  canSendErrorNcToResolve = () => {
    return commonDataAccess.getData("user/get/canSendErrorNcToResolve");
  };

  getAllUserWithEmail = () => {
    return commonDataAccess.getData("user/get/getAllUserWithEmail");
  };
  getUserAndDepartment = () => {
    return commonDataAccess.getData("user/get/userAndDepartment");
  };
  exportDataToExcel = () => {
    return commonDataAccess.downloadFile("user/export/peopleDataToExcel");
  };

  getCreditAdviserListsForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "user/get/creditAdviserListsForDropdown",
      {}
    );
    return result;
  };

  getAverageLoanAmount = async (bdmId: any) => {
    return commonDataAccess.getData("user/get/averageLoanAmount", {
      bdmId: bdmId,
    });
  };
  getApplicationToSettlementConversionRate = async (bdmId: any) => {
    return commonDataAccess.getData(
      "user/get/applicationToSettlementConversionRate",
      {
        bdmId: bdmId,
      }
    );
  };

  getLeadToApplicationConversionRate = async (bdmId: any) => {
    return commonDataAccess.getData(
      "user/get/leadToApplicationConversionRate",
      {
        bdmId: bdmId,
      }
    );
  };

  getFiscalYears = async () => {
    return commonDataAccess.getData("staffKpi/get/fiscalYears");
  };

  getManagerListsForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "user/get/managerListsForDropdown",
      {}
    );
    return result;
  };

  getEmployeeListsForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "user/get/employeeListsForDropdown",
      {}
    );
    return result;
  };
}
export default new peopleDataAccess();
