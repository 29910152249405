import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";
import { accountReducer } from "./account/acountRedux";
import { productReducer } from "./products/productReducer";

import { listReducer } from "./listRedux";
import { Dispatch } from "react";
import { peopleReducer } from "./peoples/peoplesReducer";
import { departmentReducer } from "./departments/departmentReducer";

import { commonReducer } from "./common/commonReducer";

import { documentHistoryReducer } from "./documents/documentHistory.reducer";

import { auditLogReducer } from "./auditChangeLog/auditChangeLog.reducer";

import { settingsReducer } from "./Settings/settingsReducer";
import { clientReducer } from "./clients/clientReducer";
import { commissionPaymentReducer } from "./commissionPayments/commissionPaymentReducer";
import { clientProductReducer } from "./clientProducts/clientProductReducer";
import { expenseReducer } from "./expenses/expenseReducer";

export interface RootAction extends Action {
  payload: any;
}

export interface IListType {
  pageNo: number;
  pageSize: number;
  sortData: string;
  filters: any[];
}

const combineReducer = combineReducers({
  user: accountReducer,
  product: productReducer,
  people: peopleReducer,
  department: departmentReducer,
  //company: companyReducer,
  //training: trainingReducer,
  //location: locationReducer,
  documentHistory: documentHistoryReducer,
  auditChangeLog: auditLogReducer,
  settings: settingsReducer,
  client: clientReducer,
  expenseState: expenseReducer,
  clientProduct: clientProductReducer,
  CommissionPayment: commissionPaymentReducer,
  listState: listReducer,
  common: commonReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "[Account] Logout user success") {
    state = undefined;
  }
  return combineReducer(state, action);
};

// const root = createReducer()

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch | Dispatch<any>;
export type RootState = ReturnType<typeof combineReducer>;
